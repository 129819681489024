<template>
	<SearchResultListItem
		:result="result"
		:is-mobile="isMobile"
		:list-type="result.listType"
	>
		<template #row2="slotProps">
			<img
				:src="result.icon"
				alt="Category Icon"
				class="mr-2"
				width="15"
				height="15"
			>
			<span class="mr-2 text-sm capitalize">
				{{ result.category }}
			</span>
			<span
				v-if="slotProps.hasStrainType"
				class="mr-2 text-gray-300"
			>
				•
			</span>
			<span class="text-sm capitalize truncate">
				{{ result.strainType }}
			</span>
		</template>

		<template #row3>
			<div class="h-6" />
		</template>
	</SearchResultListItem>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	}
}
</script>
